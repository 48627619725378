import React, {useEffect} from 'react'
import {motion} from "framer-motion";

function OnlineApplicationDetails() {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
        <div className={'py-10 text-center'}>
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 1,
                    ease: [0, 0.71, 0.2, 1.01]
                }}
                viewport={{ once: true, amount: 0.8 }}
            >
                <div className={'text-3xl'}>Online Application Form</div>
                <div className={'container p-4 md:p-10'}>
                    Before step towards the application, There are few things to know...

                    <div className={'mt-3 p-6 md:p-10 text-justify bg-gray-100'}>
                        Dear Student, <br /> <br />
                        We recommend our students to complete the application process online before visiting to the interview at college premises. Therefore we would like to share few steps to complete the application.
                        <br /><br />Thank You!
                    </div>

                    <div className={'p-6 md:p-10 text-justify'}>
                        <div className={'py-4'}>
                            You need to have an email address accessible by you at the moment of completing the application. If you already have an email account make sure you remember the email address and password.
                        </div>
                        <div  className={'py-4'}>
                            If you don't have an email yet, please create an email address before starting the application. An email account is important to complete the application as your printable application will be sent to your email address at last. You need to take a print out of the emailed application and should be submitted to the office on the given date for the interview.
                        </div>
                        <div  className={'py-4'}>
                            We recommend our students to have their own email address as our school messages will be notified via e-mail in future.
                        </div>

                        <div className={'py-4'}>
                            Documents needed to be submitted at the interview board. <br />

                            <ul  className={'py-4'}>
                                <li>Birth Certificate (Copy)</li>
                                <li>Applicant's NIC (Original, Copy)</li>
                                <li>Student's Progress Report (Copy)</li>
                            </ul>
                        </div>
                    </div>
                    <div className={'p-6 md:p-10 text-justify'}>
                        <div className={'text-2xl py-3'}>STEPS</div>
                        <div className={''}>
                            <div className={'bold text-lg pt-2 shadow-md rounded p-3 bg-indigo-50'}>Step 1</div>
                            <div className={'p-3'}>
                                Click <a href={'https://sms.clcsl.edu.lk/#/0/online-application'} target={'_blank'} rel="noreferrer" className={'py-2 px-3 w-full bg-blue-500 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none'}> Here</a> to open the application.
                                {/* Click <a href={'http://localhost:3001/enrollment/application'} target={'_blank'} rel="noreferrer" className={'py-2 px-3 w-full bg-blue-500 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none'}> Here</a> to open the application. */}
                            </div>
                        </div>
                        <div className={'py-4'}>
                            <div className={'bold text-lg pt-2 shadow-md rounded p-3 bg-indigo-50'}>Step 2</div>
                            <div className={'p-3'}>
                                Read the instructions given in the application and fill each fields related to you. <br /> <br/>
                                You can start filling your application by uploading a recently taken image of you. (Optional) <br /> <br/>
                                At middle of the application, you will be asked to enter your email address (Important). Please give your email address correctly, to receive the printable format via e-mail which should be submitted at the interview board. <br /> <br/>
                                <img src={'https://sms.clcsl.edu.lk/serverclc/upload/wp-content/uploads/2021/03/2-1024x732.jpg'} alt={'Step 02 Screenshot'} />
                            </div>
                        </div>
                        <div className={'py-4'}>
                            <div className={'bold text-lg pt-2 shadow-md rounded p-3 bg-indigo-50'}>Step 3</div>
                            <div className={'p-3'}>
                                After duly completing the fields, Please click the "Next Button" to verify your application. <br /> <br/>
                                Check all the given information are correct. You can edit your application at this stage. Edit if you have any information to be changed. If everything looks fine, accept the agreements given by CLC and submit the application.
                                <img src={'https://sms.clcsl.edu.lk/serverclc/upload/wp-content/uploads/2021/03/one-more-step.jpg'} alt={'Step 03 Screenshot'} />
                                <img src={'https://sms.clcsl.edu.lk/serverclc/upload/wp-content/uploads/2021/03/accept-and-submit.jpg'} alt={'Step 03 -1 Screenshot'} />

                            </div>
                        </div>
                        <div className={'py-4'}>
                            <div className={'bold text-lg pt-2 shadow-md rounded p-3 bg-indigo-50'}>Step 4</div>
                            <div className={'p-3'}>
                                That's all. You can now check your email. A link to the printable application is given in your email. Please click to download your application and print.  <br /> <br/>
                                In some cases your email service provider will catch our email as a spam email and store it in the spam folder. Make sure to confirm that the email is from info@clcsl.edu.lk and it is safe to open. <br /><br />
                                Note: Your sensitive information given to us will not be shared with any third-parties in any situation.
                                <img src={'https://sms.clcsl.edu.lk/serverclc/upload/wp-content/uploads/2021/03/Click-the-download-button-to-finish.jpg'} alt={'Step 04 Screenshot'} />
                            </div>
                        </div>
                        <div className={'py-4'}>
                            <div className={'bold text-lg pt-2 shadow-md rounded p-3 bg-indigo-50'}>Step 5</div>
                            <div className={'p-3'}>
                                Download and print the application<br /> <br/>
                                Successful! <br /><br />
                                <img src={'https://sms.clcsl.edu.lk/serverclc/upload/wp-content/uploads/2021/03/download.jpg'} alt={'Step 05 Screenshot'} />
                                <img src={'https://sms.clcsl.edu.lk/serverclc/upload/wp-content/uploads/2021/03/donwloaded-application.jpg'} alt={'Step 05-1 Screenshot'} /> 
                                <br />
                                You can fill in the blank areas appear in the printed application and attend to the interview on the date informed by us to you. <br /><br />
                                Documents needed to be submitted at the interview board. <br /><br />
                                Birth Certificate (Copy) <br />
                                Applicant's NIC (Original, Copy)<br />
                                Student's Progress Report (Copy)
                            </div>
                        </div>
                        <div>
                            <a href={'https://sms.clcsl.edu.lk/#/0/online-application'} target={'_blank'} rel="noreferrer" className={'py-2 px-3 w-full bg-blue-500 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none'}> Click Here to go to the application!</a>

                            {/* <a href={'http://localhost:3001/enrollment/application'} target={'_blank'} rel="noreferrer" className={'py-2 px-3 w-full bg-blue-500 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none'}> Click Here to go to the application!</a> */}
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default OnlineApplicationDetails